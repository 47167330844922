<template>
  <section class="permission-wrapper">
    <card title="权限管理">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="dialogFormVisible = true"
        >添加用户</el-button
      >
      角色选择:<el-radio-group
        v-model="role"
        style="margin-left:20px"
        @change="changeRole"
      >
        <el-radio-button
          v-for="(item, index) in roleMap"
          :key="index"
          :label="item.text"
        ></el-radio-button>
      </el-radio-group>
      <el-checkbox-group v-model="permissionList" :disabled="role === '老板'">
        <el-checkbox
          :label="item.value"
          v-for="(item, index) in permissionMap"
          :key="index"
          border
          @change="changePermission(item.value)"
          >{{ item.text }}</el-checkbox
        >
      </el-checkbox-group>

      <el-table
        ref="filterTable"
        :data="roleList"
        border
        style="margin-top:20px"
      >
        <el-table-column prop="role" label="角色" align="center">
        </el-table-column>
        <el-table-column prop="username" label="用户名" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button size="mini" @click="logOut(scope.$index, scope.row)"
              >注销</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="新增用户"
        :visible.sync="dialogFormVisible"
        @close="closeForm"
      >
        <el-form
          :model="roleForm"
          label-position="left"
          class="permission-form"
          ref="roleform"
          :rules="rules"
        >
          <el-form-item label="用户身份:" label-width="90px" prop="type">
            <el-select v-model="roleForm.role">
              <el-option
                v-for="item in roleMap"
                :key="item.value"
                :label="item.text"
                :value="item.text"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名:" label-width="90px" prop="username">
            <el-input
              v-model="roleForm.username"
              autocomplete="off"
              class="form-input"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码:" label-width="90px" prop="password">
            <el-input
              v-model="roleForm.password"
              autocomplete="off"
              class="form-input"
              placeholder="请输入密码"
              @keyup.enter.native="submitForm"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="commitRole">确 定</el-button>
        </div>
      </el-dialog>
    </card>
  </section>
</template>

<script>
import card from "../../components/card";
export default {
  components: {
    card
  },
  data() {
    var validateUsrname = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        username: [{ validator: validateUsrname, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }]
      },
      permissionList: [],
      rolePermission: [],
      role: "",
      roleMap: [
        { text: "老板", value: "0" },
        { text: "总负责人", value: "1" },
        { text: "客服人员", value: "2" },
        { text: "仓库管理", value: "3" }
      ],
      permissionMap: [
        { text: "商品管理", value: "0" },
        { text: "订单管理", value: "1" },
        { text: "运费管理", value: "2" },
        { text: "库存管理", value: "3" },
        { text: "会员管理", value: "4" },
        { text: "机构管理", value: "5" },
        { text: "历史数据分析", value: "6" }
      ],
      allUser: [],
      dialogFormVisible: false,
      roleForm: {
        role: "老板",
        username: "",
        password: ""
      }
    };
  },
  computed: {
    roleList() {
      return this.allUser.filter(item => {
        return item.role == this.role;
      });
    }
  },
  methods: {
    closeForm() {
      this.$refs.roleform.resetFields();
    },
    logOut(index, row) {
      this.$axios({
        url: "/deleteUser",
        params: {
          userId: row.userId
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "注销成功"
          });
          this.getUser();
        } else {
          this.$message({
            type: "error",
            message: res.data.msg
          });
        }
      });
    },
    commitRole() {
      this.dialogFormVisible = true;
      this.$refs["roleform"].validate(valid => {
        if (valid) {
          this.$axios({
            url: "/addUser",
            method: "post",
            transformRequest: [
              function(data) {
                return JSON.stringify(data);
              }
            ],
            data: this.roleForm,
            headers: { "Content-Type": "application/json" }
          }).then(res => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "添加用户成功"
              });
              this.getUser();
              this.dialogFormVisible = false;
            } else {
              this.$message({
                type: "error",
                message: res.data.msg
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    changeRole() {
      for (let item of this.roleMap) {
        if (item.text == this.role) {
          this.permissionList = this.rolePermission[item.value];
        }
      }
    },
    changePermission(val) {
      if (this.permissionList.indexOf(val) !== -1) {
        this.$axios({
          url: "/addPerm",
          params: {
            permission: val,
            role: this.role
          }
        }).then(res => {
          this.getPermission();
        });
      } else {
        this.$axios({
          url: "/deletePerm",
          params: {
            permission: val,
            role: this.role
          }
        }).then(res => {
          this.getPermission();
        });
      }
    },
    getPermission() {
      return this.$axios({
        url: "/getPermByRole"
      })
        .then(res => {
          if (res.data.code == 200) {
            this.rolePermission = [];
            for (let item of this.roleMap) {
              this.rolePermission.push(res.data.data[item.text]);
            }
            this.changeRole();
          } else {
            this.$message({
              type: "error",
              message: "获取信息错误"
            });
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: "获取信息失败"
          });
        });
    },
    getUser() {
      return this.$axios({
        url: "/getAllUserInfo"
      })
        .then(res => {
          if (res.data.code == 200) {
            this.allUser = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: "获取信息错误"
            });
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: "获取信息失败"
          });
        });
    }
  },
  created() {
    this.$openLoading();
    this.role = this.roleMap[0].text;
    Promise.all([this.getPermission(), this.getUser()]).then(res => {
      this.changeRole();
      setTimeout(() => {
        this.$openLoading().close();
      }, 200);
    });
  }
};
</script>

<style lang="less">
.permission-wrapper {
  .el-checkbox.is-bordered {
    margin-top: 20px;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
  }

  .permission-form {
    margin: 0 auto;
    width: 76%;

    .el-select {
      .el-input {
        width: 100% !important;
      }
    }
    .form-input {
      margin: 0 auto;
    }
  }
}
</style>
